import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createAuth0, createAuthGuard } from "@auth0/auth0-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import App from "./App.vue";
import LandingPage from "./pages/LandingPage.vue";
import ProfilePage from "./pages/ProfilePage.vue";
import AdminPage from "./pages/AdminPage.vue";
import SnailMailPage from "./pages/SnailMail.vue";

const app = createApp(App);

library.add(fas, far, fab);

app.component("font-awesome-icon", FontAwesomeIcon);

app.use(
  createAuth0({
    domain: "blything.eu.auth0.com",
    clientId: "jRArVItr8Xs56qikxoamXNhDVWPuG6FI",
    authorizationParams: {
      redirect_uri: window.location.origin,
    },
  })
);

app.use(
  createRouter({
    routes: [
      {
        path: "/",
        name: "home",
        component: LandingPage,
      },
      {
        path: "/profile",
        name: "profile",
        component: ProfilePage,
        beforeEnter: createAuthGuard(app),
      },
      {
        path: "/admin",
        name: "admin",
        component: AdminPage,
        beforeEnter: createAuthGuard(app),
      },
      {
        path: "/snail-mail",
        name: "snailMail",
        component: SnailMailPage,
        alias: "/snailmail"
      },
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: LandingPage,
      },
    ],
    history: createWebHistory(),
  })
);

app.mount("#app");
