<script setup>
</script>

<template>
  <div id="cover">
    <div class="grid-x cell small-12">
      <div class="cell small-12 text-center">
        <div id="snail-header" class="rubik-m500">Welcome to Snail Mail!</div>
      </div>

      <div class="cell small-12 text-center">
        <img id="post-box" />
      </div>

      <div class="cell small-12 text-center">
        <div id="snail-header" class="rubik-m500">Coming soon!</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/snail-mail/snail-mail.scss";
</style>
