<script setup>
import { ref, watch, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { Slide } from "vue3-burger-menu";
import HeaderSection from "./components/HeaderSection.vue";
import FooterSection from "./components/FooterSection.vue";

const isDark = ref(false);
const showMenu = ref(true);
const { isAuthenticated } = useAuth0();

const initialize = () => {
  isDark.value = localStorage.getItem("darkMode") == "true";
  showMenu.value = isAuthenticated.value;
};
onMounted(() => initialize());

watch(
  () => isAuthenticated.value,
  (status) => {
    showMenu.value = isAuthenticated.value;
  }
);

function toggleDark() {
  isDark.value = !isDark.value;
  localStorage.setItem("darkMode", isDark.value);
}
</script>

<template>
  <div id="app" :class="{ dark: isDark }">
    <Slide no-overlay :burger-icon="showMenu">
      <img
        src="../src/assets/images/blything-menu-logo.png"
        style="width: 80%"
      />
      <a id="home" href="#">
        <span>Home</span>
      </a>
    </Slide>
    <div id="page-stretch">
      <div id="header">
        <HeaderSection />
      </div>

      <div id="page-content" class="grid-x grid-padding-x">
        <router-view />
      </div>
    </div>

    <div id="footer" class="footer">
      <FooterSection @toggle-dark="toggleDark" />
    </div>
  </div>
</template>

<style lang="scss">
@import "./assets/styles/main.scss";
</style>