<script setup>
import { watch } from "vue";
import { useRouter } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";

const { loginWithPopup, logout, isAuthenticated } = useAuth0();
const router = useRouter();
const handleLogin = async () => {
  try {
    await loginWithPopup();
  } catch (e) {}
};
const handleLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } });
};

if (isAuthenticated.value) router.push("profile");

watch(
  () => isAuthenticated.value,
  (status) => {
    if (status) router.push("profile");
  }
);
</script>

<template>
  <div class="grid-x cell small-12">
    <div class="cell medium-2 large-3"></div>
    <div class="text-wrapper cell small-12 medium-8 large-6">
      <h1 id="welcome">Welcome to Blythings!</h1>
    </div>
    <div class="cell medium-2 large-3"></div>

    <div class="cell medium-2 large-3"></div>

    <div class="cell text-wrapper small-12 medium-8 large-6 center-align">
      <div v-if="!isAuthenticated" class="btn" @click="handleLogin">Login</div>
      <div v-else class="btn" @click="handleLogout">Logout</div>
    </div>

    <div class="cell medium-2 large-3"></div>
  </div>
</template>

<style lang="scss">
@import "../assets/styles/landing.scss";
</style>
