<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { logout, user, idTokenClaims, isAuthenticated, getAccessTokenSilently } =
  useAuth0();
const handleLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } });
};
</script>

<template>
  <div class="grid-x">
    <div class="cell small-0 medium-1 large-1" />

    <div class="cell small-12 medium-10 large-10 white-section content-section">
      <div class="grid-x">
        <div class="cell small-12 medium-12 large-12">
          <div class="main-content">
            <h1 class="center-text">Admin</h1>
            <p>
              {{ user.meta_roles }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../assets/styles/admin.scss";
</style>
