<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
const { logout, isAuthenticated } = useAuth0();

const handleLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } });
};
</script>

<template>
  <div>
    <div class="header grid-x">
      <div class="cell small-0 medium-1 large-1"></div>
      <div class="grid-x cell small-12 medium-10 large-10 white-section">
        <div id="head-logo-mobile" class="show-for-small-only">
          <!-- <img src="../assets/images/blything-head-logo.png" /> -->
        </div>
        <div id="head-logo" class="cell medium-4 large-4 hide-for-small-only">
          <!-- <img src="../assets/images/blything-head-logo.png" /> -->
        </div>
        <div class="cell medium-8 large-8 hide-for-small-only">
          <ul v-show="isAuthenticated" id="head-menu-list">
            <a href="#">
              <li>
                <font-awesome-icon :icon="['fas', 'house']" />
                <span>Home</span>
              </li>
            </a>
            <a href="#/profile">
              <li>
                <font-awesome-icon :icon="['fas', 'user']" />
                <span>Profile</span>
              </li>
            </a>
            <a @click="handleLogout">
              <li>
                <font-awesome-icon
                  :icon="['fas', 'arrow-right-from-bracket']"
                />
                <span>Logout</span>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../assets/styles/header.scss";
</style>
