<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { logout, user, isAuthenticated } = useAuth0();
const handleLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } });
};
</script>

<template>
  <div class="grid-x">
    <div class="cell small-0 medium-1 large-1" />

    <div class="cell small-12 medium-10 large-10 white-section content-section">
      <div class="grid-x">
        <!-- <div class="cell small-12 medium-12 large-12">
          
        </div> -->

        <div class="cell medium-3 show-for-medium-only"></div>
        <!-- Look into push/pull-->
        <div class="cell small-12 medium-6 large-3">
          <div class="card-user-container">
            <!--card's image-->
            <div class="card-user-avatar">
              <img :src="user.picture" alt="" class="user-image" />
            </div>

            <!--user info name, bio and location-->
            <div class="card-user-bio">
              <h4>{{ user.meta_firstname }} {{ user.meta_lastname }}</h4>
              <p>{{ user.meta_role }}</p>
              <span v-if="user.meta_location" class="location">
                <span class="location-icon fa fa-map-marker"></span>
                <span class="location-text">{{
                  user.meta_location.address_line1 || ""
                }}</span>
                <span class="location-text">{{
                  user.meta_location.address_line2 || ""
                }}</span>
                <span class="location-text">{{
                  user.meta_location.address_line3 || ""
                }}</span>
                <span class="location-text">{{
                  user.meta_location.postcode || ""
                }}</span>
              </span>
            </div>

            <!--card's socail icons-->
            <div class="card-user-social">
              <ul class="menu">
                <li class="fa-solid fa-user"></li>
                <li><font-awesome-icon :icon="['fab', 'facebook']" /></li>
                <li><font-awesome-icon :icon="['fab', 'twitter']" /></li>
                <li><font-awesome-icon :icon="['fab', 'dribbble']" /></li>
                <li><font-awesome-icon :icon="['fab', 'instagram']" /></li>
                <li><font-awesome-icon :icon="['fab', 'github']" /></li>
                <li><font-awesome-icon :icon="['fab', 'tiktok']" /></li>
                <li><font-awesome-icon :icon="['fab', 'linkedin']" /></li>
              </ul>
            </div>

            <!--card's follow button-->
            <div class="card-user-button">
              <!-- <a href="#" class="hollow button">FOLLOW</a> -->
              <a class="btn" @click="handleLogout">LOGOUT</a>
            </div>
          </div>
        </div>

        <div class="cell small-12 medium-12 large-9">
          <div class="main-content">
            <h1 class="center-text">Profile</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              efficitur sed lectus et finibus. Quisque auctor nibh vel erat
              dignissim tempus. Ut tempus pharetra volutpat. Aenean nulla erat,
              fringilla sed aliquet at, ornare ut enim. Aenean gravida arcu
              neque, at tincidunt libero tincidunt eu. Nullam sit amet purus ut
              orci dignissim blandit. Etiam odio urna, viverra sit amet
              sollicitudin quis, condimentum eget lectus. Morbi facilisis justo
              vel neque ultricies, in finibus sem mollis. Vivamus eu congue
              justo.
            </p>
            <p>
              Donec aliquam justo ac leo iaculis lacinia. Donec a lectus
              scelerisque, facilisis orci id, feugiat risus. Nunc suscipit nunc
              eget erat mattis posuere. Phasellus cursus lacinia scelerisque.
              Morbi egestas felis ut ipsum tempus auctor. Etiam pretium sodales
              ipsum a posuere. Fusce at enim tristique, egestas neque sed,
              consectetur nunc. Nulla eleifend hendrerit iaculis. Integer sed
              tempus dui. Phasellus est dolor, vulputate et laoreet nec,
              tincidunt non arcu. Suspendisse potenti. Mauris vitae interdum
              arcu. Etiam semper congue mauris eget ullamcorper. Donec vitae
              enim at arcu rutrum blandit.
            </p>
            <p>
              Fusce sed porta ex. Suspendisse potenti. Mauris vel elit
              vestibulum, aliquam eros at, vehicula risus. Pellentesque habitant
              morbi tristique senectus et netus et malesuada fames ac turpis
              egestas. Etiam nulla lacus, tristique a libero a, ullamcorper
              bibendum ex. Sed sit amet mi sit amet elit aliquam cursus ac non
              erat. Integer rhoncus efficitur facilisis. Curabitur nisi purus,
              euismod id ultrices id, consectetur ac massa. Vestibulum
              ullamcorper porta turpis et sollicitudin. Etiam dictum ornare
              malesuada. Duis non metus euismod, dapibus eros a, tempus massa.
              Ut nec posuere nisi, quis egestas leo. Donec est augue, rutrum ac
              ultricies eu, aliquet id orci. Fusce vel urna sit amet risus
              auctor ornare.
            </p>
            <p>
              Integer rutrum tellus ac metus efficitur sodales. Pellentesque
              habitant morbi tristique senectus et netus et malesuada fames ac
              turpis egestas. Integer finibus placerat aliquam. Nunc porta elit
              mauris, vel fermentum sem malesuada quis. Donec sagittis ultrices
              quam, a vulputate est iaculis id. Integer accumsan, metus pulvinar
              porta vestibulum, purus metus ullamcorper nunc, a interdum lectus
              mi maximus mi. Aenean et aliquet mi. Donec sit amet consequat
              ipsum, in pretium massa. Sed vestibulum nisi a eleifend mattis.
              Nullam nec nunc volutpat, tincidunt dolor in, lacinia est. Sed
              aliquam nunc vitae finibus suscipit. Proin felis dui, semper et
              tincidunt ac, fermentum sollicitudin erat. Curabitur at viverra
              felis, nec rhoncus erat.
            </p>
            <p>
              Nunc sodales, velit eleifend eleifend hendrerit, dolor justo
              pulvinar mi, eget luctus felis purus eget arcu. Aliquam et arcu
              tortor. Duis cursus luctus mauris maximus varius. Pellentesque
              vitae tincidunt ipsum, in consequat diam. Suspendisse mauris nisl,
              imperdiet nec nisi ac, sodales vestibulum turpis. Donec ut sem ut
              mauris mollis accumsan quis vel leo. Nullam mattis vestibulum
              lobortis. Fusce nec blandit tortor. Nam congue tortor gravida
              congue elementum. Maecenas a ligula in diam lobortis malesuada at
              eget erat.
            </p>
            <p>
              Nunc sodales, velit eleifend eleifend hendrerit, dolor justo
              pulvinar mi, eget luctus felis purus eget arcu. Aliquam et arcu
              tortor. Duis cursus luctus mauris maximus varius. Pellentesque
              vitae tincidunt ipsum, in consequat diam. Suspendisse mauris nisl,
              imperdiet nec nisi ac, sodales vestibulum turpis. Donec ut sem ut
              mauris mollis accumsan quis vel leo. Nullam mattis vestibulum
              lobortis. Fusce nec blandit tortor. Nam congue tortor gravida
              congue elementum. Maecenas a ligula in diam lobortis malesuada at
              eget erat.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../assets/styles/profile.scss";
</style>
